import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/Iconify';
import { setUserData } from 'src/storage/userSessionStorage';
import { loginService } from '../../services/AuthServices';
import { useToasts } from '../../hooks/useToasts';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const toast = useToasts();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    user: Yup.string().required('Usuario es requerido'),
    password: Yup.string().required('Cotraseña es requerida')
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      loginSubmit(values);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const loginSubmit = async (form) => {
    loginService({
      email: form.user,
      password: form.password
    })
      .then((r) => {
        console.log(r);
        setUserData({ token: r.token, user: r.user });
        navigate('/');
      })
      .catch(() => {
        formik.setSubmitting(false);
        toast.error('Credenciales incorrectas');
      });
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Usuario"
            {...getFieldProps('user')}
            error={Boolean(touched.user && errors.user)}
            helperText={touched.user && errors.user}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Iniciar Sesión
          </LoadingButton>
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack> */}
      </Form>
    </FormikProvider>
  );
}
