import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Container, Typography, TextField, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useToasts } from '../../hooks/useToasts';

// components
import Page from '../../components/Page';
import { createDocument, editDocument, getDocument } from 'src/services/DocumentServices';

// ----------------------------------------------------------------------

export default function DocumentForm() {
  const toast = useToasts();
  const navigate = useNavigate();
  const { id } = useParams();

  const DocumentSchema = Yup.object({
    titular: Yup.string(),
    url: Yup.string(),
    text: Yup.string().required('Campo requerido'),
    topics: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      titular: '',
      url: '',
      text: '',
      topics: ''
    },
    validationSchema: DocumentSchema,
    onSubmit: () => {
      onSubmit(values);
    }
  });

  const onSubmit = (data) => {
    try {
      if (id) {
        editDocument(data, id).then(() => {
          toast.success('Documento editado exitosamente');
          navigate('/');
        });
      } else {
        createDocument(data).then(() => {
          toast.success('Documento creado exitosamente');
          navigate('/');
        });
      }
    } catch {
      toast.error(
        id ? 'Ocurrió un error al editar el documento' : 'Ocurrió un error al crear el documento'
      );
    }
  };

  const { errors, touched, handleSubmit, isSubmitting, values, setFieldValue, getFieldProps } =
    formik;

  useEffect(() => {
    if (id) {
      getDocument(id).then((x) => {
        Object.keys(x).forEach((k) => {
          const p = x[k];
          setFieldValue(k, p);
        });
      });
    }
  }, [id]);

  return (
    <Page title="Documents">
      <Container>
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {id ? 'Editar' : 'Crear'} Documento
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ marginBottom: '10px' }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Titular"
                  {...getFieldProps('titular')}
                  error={Boolean(touched.titular && errors.titular)}
                  helperText={touched.titular && errors.titular}
                />
                <TextField
                  fullWidth
                  label="Url"
                  {...getFieldProps('url')}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                />
              </Stack>
              <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  multiline
                  rows={7}
                  fullWidth
                  label="Contenido"
                  {...getFieldProps('text')}
                  error={Boolean(touched.text && errors.text)}
                  helperText={touched.text && errors.text}
                />
              </Stack>
              <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Temas"
                  {...getFieldProps('topics')}
                  error={Boolean(touched.topics && errors.topics)}
                  helperText={touched.topics && errors.topics}
                />
              </Stack>
              <Stack spacing={3} alignItems={'end'} direction={{ xl: 'column' }}>
                <Box>
                  {id && (
                    <Button
                      color="inherit"
                      variant="contained"
                      size="large"
                      onClick={() => {
                        navigate(-1);
                      }}
                      sx={{ bgcolor: 'primary.main', color: 'background.paper', marginRight: 1 }}
                    >
                      Cancelar
                    </Button>
                  )}
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Guardar
                  </LoadingButton>
                </Box>

                <Box></Box>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
