import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <RouterLink to="/"></RouterLink>;
}
