const userDataKey = 'USER_DATA';

const setUserData = (data) => {
  sessionStorage.setItem(userDataKey, JSON.stringify(data));
};

const getUserData = () => {
  const data = sessionStorage.getItem(userDataKey);
  return data ? JSON.parse(data) : { token: null };
};

const clearUserData = () => {
  sessionStorage.removeItem(userDataKey);
};

export { setUserData, getUserData, clearUserData };
