import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Stack, Container, Typography, Paper, Button, Box } from '@mui/material';

// components
import Page from '../../components/Page';
import { getDocument } from 'src/services/DocumentServices';

// ----------------------------------------------------------------------

export default function DocumentDetail() {
  const { id } = useParams();
  const [document, setDocument] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getDocument(id).then((x) => setDocument(x));
  }, [id]);

  return (
    <Page title="Documents">
      <Container>
        <Paper sx={{ px: 5, py: 5 }}>
          <Stack sx={{ mb: 5 }} alignItems={'flex-end'}>
            <Box>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => {
                  navigate('/dashboard/app');
                }}
                sx={{ bgcolor: 'primary.main', color: 'background.paper', marginRight: 1 }}
              >
                Regresar
              </Button>
            </Box>
          </Stack>
          <Typography variant="h4" gutterBottom>
            {document?.titular}
          </Typography>
          {document?.topics && (
            <Typography variant="h6" color={'text.secondary'} gutterBottom>
              {document?.topics}
            </Typography>
          )}
          <small>
            <a href={document?.url}>{document?.url}</a>
          </small>
          <br />
          <br />
          {document?.text}
        </Paper>
      </Container>
    </Page>
  );
}
