import axios from 'axios';

async function loginService(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/auth/login/', cred);
  const data = result.data;
  return data;
}

export { loginService };
