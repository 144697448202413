import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getAll() {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.get(`/v1/documents`)).data;
}

async function deleteDocuments(ids) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.post(`/v1/index/deleteDocuments`, ids)).data;
}

async function createDocument(dto) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.post(`/v1/index/addDocument`, dto)).data;
}

async function editDocument(dto, id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.post(`/v1/index/editDocument/${id}`, dto)).data;
}

async function updateIndex(dto) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.post(`/v1/index/updateIndex`)).data;
}

async function addDocuments(dto) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.post(`/v1/index/addDocuments`, dto)).data;
}

async function getDocument(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return (await axiosClient.get(`/v1/documents/${id}`)).data;
}

export {
  getAll,
  deleteDocuments,
  createDocument,
  updateIndex,
  addDocuments,
  getDocument,
  editDocument
};
