import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  Box
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useToasts } from 'src/hooks/useToasts';
import { addDocuments } from 'src/services/DocumentServices';
import readXlsxFile from 'read-excel-file';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

DocumentsToolbar.propTypes = {
  numSelected: PropTypes.number,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  onDelete: PropTypes.func
};

export default function DocumentsToolbar({ numSelected, filter, onFilter, onDelete }) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const btnRef = useRef();
  const toast = useToasts();

  const importExcel = () => {
    btnRef.current.click();
  };

  const schema = {
    titular: {
      prop: 'titular',
      type: String
    },
    url: {
      prop: 'url',
      type: String,
      required: true
    },
    text: {
      prop: 'text',
      type: String,
      required: true
    },
    topics: {
      prop: 'topics',
      type: String
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    readXlsxFile(file, { schema }).then(({ rows, errors }) => {
      if (errors?.length) {
        toast.error('Ocurrió un error al importar los documentos');
      }
      setData(rows);
    });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const d = data[i];
        if (!('text' in d) || !('url' in d) || !('titular' in d)) {
          toast.error('El excel no tiene un formato correcto');
          return;
        }
      }
      addDocuments(data).then((x) => {
        if (x.length !== data.length)
          toast.warning(`Se importaron ${x.length} de ${data.length} documentos`);
        else toast.success(`Se importaron ${x.length} documentos`);
      });
    }
  }, [data]);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'text.primary',
          bgcolor: 'secondary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {numSelected === 1 ? 'seleccionado' : 'seleccionados'}
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filter}
            onChange={onFilter}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Box>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                navigate('/document/form');
              }}
              sx={{ bgcolor: 'primary.main', color: 'background.paper', marginRight: 1 }}
            >
              Agregar
            </Button>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                importExcel();
              }}
              sx={{ bgcolor: 'primary.main', color: 'background.paper' }}
            >
              Importar Excel
            </Button>
            <input type="file" onChange={handleFileUpload} hidden ref={btnRef} />
          </Box>
        </>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={onDelete}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
