// material
import {
  Grid,
  Container,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { deleteDocuments, getAll } from 'src/services/DocumentServices';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import DocumentsListHead from 'src/sections/documents/DocumentsListHead';
import DocumentsToolbar from 'src/sections/documents/DocumentsToolbar';
import { useConfirm } from 'material-ui-confirm';
import { useToasts } from 'src/hooks/useToasts';
import DocumentsMoreMenu from 'src/sections/documents/DocumentsMoreMenu';
import { filter } from 'lodash';

const TABLE_HEAD = [
  { id: 'titular', label: 'Título', alignRight: false },
  { id: 'url', label: 'URL', alignRight: false },
  { id: '' }
];

function applyFilter(array, query) {
  if (query) {
    return filter(
      array,
      (doc) =>
        doc.titular.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        doc.url.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return array;
}

export default function DashboardApp() {
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const toast = useToasts();
  const confirm = useConfirm();

  useEffect(() => {
    getAll().then((x) => setDocs(x));
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = docs.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    confirm({
      description: 'Se eliminará permanentemente este documento',
      title: 'Está seguro'
    })
      .then(() => {
        deleteDocuments([id])
          .then(() => setDocs((prev) => prev.filter((x) => x._id !== id)))
          .catch(() => {
            toast.error('No se pudo eliminar el usuario');
          });
      })
      .catch(() => {});
  };

  const handleDeleteSelected = () => {
    confirm({
      description: 'Se eliminará permanentemente los documentos seleccionados',
      title: 'Está seguro'
    })
      .then(() => {
        deleteDocuments(selected)
          .then(() => setDocs((prev) => prev.filter((x) => selected.indexOf(x._id) >= 0)))
          .catch(() => {
            toast.error('No se pudo eliminar el usuario');
          });
      })
      .catch(() => {});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - docs.length) : 0;
  const isUserNotFound = docs.length === 0;

  const filteredDocs = applyFilter(docs, search);

  return (
    <Page title="Documentos">
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card>
              <CardContent>
                <DocumentsToolbar
                  numSelected={selected.length}
                  filter={search}
                  onFilter={handleSearch}
                  onDelete={handleDeleteSelected}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table sx={{ width: '95%' }}>
                      <DocumentsListHead
                        headLabel={TABLE_HEAD}
                        rowCount={docs.length}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredDocs
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { titular, url, _id } = row;

                            const isItemSelected = selected.indexOf(_id) !== -1;

                            return (
                              <TableRow
                                key={_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onChange={(event) => handleClick(event, _id)}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <div
                                    style={{
                                      width: '100%',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                    }}
                                  >
                                    {titular}
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div
                                    style={{
                                      width: '400px',
                                      wordBreak: 'break-all'
                                    }}
                                  >
                                    <a href={url}>{url}</a>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <DocumentsMoreMenu onDelete={() => handleDelete(_id)} id={_id} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={9} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={search} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredDocs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
