import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import Login from './pages/Login';
import { getUserData } from './storage/userSessionStorage';
import { DocumentForm } from './pages/documents';
import DocumentDetail from './pages/documents/DocumentDetail';

// ----------------------------------------------------------------------

function ProtectedRoute({ children }) {
  const { token } = getUserData();

  const notAllowed = !token;

  if (notAllowed) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <DashboardApp />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/document',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'form',
          element: <DocumentForm />,
          children: [
            {
              path: ':id',
              element: <DocumentForm />
            }
          ]
        },
        {
          path: 'detail/:id',
          element: <DocumentDetail />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
