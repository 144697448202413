import { ConfirmProvider } from 'material-ui-confirm';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { ToastProvider } from './hooks/useToasts';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <ConfirmProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
      </ConfirmProvider>
    </ThemeConfig>
  );
}
